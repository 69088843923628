import { NgModule } from '@angular/core';
import { AutocompleteComponent, AutocompleteOptionDirective } from './autocomplete/autocomplete.component';
import { SystemAutocompleteComponent } from './system-autocomplete/system-autocomplete.component';
import { ResourceAutocompleteComponent } from './resource-autocomplete/resource-autocomplete.component';
import {
  AutocompleteMultipleComponent,
  AutocompleteMultipleItemDirective,
  AutocompleteMultipleOptionDirective,
} from './autocomplete-multiple/autocomplete-multiple.component';
import {
  AutocompleteChipsComponent,
  AutocompleteLabelChipsDirective,
} from './autocomplete-chips/autocomplete-chips.component';
import { GlobalModule } from 'app/modules/global/global.module';
import { GroupChipsComponent } from './group-chips/group-chips.component';
import { FeedAutocompleteComponent } from './feed-autocomplete/feed-autocomplete.component';
import { BrandAutocompleteComponent } from './brand-autocomplete/brand-autocomplete.component';
import { TemplateAutocompleteComponent } from './template-autocomplete/template-autocomplete.component';
import { SystemAutocompleteFilterFieldComponent } from './system-autocomplete-filter-field/system-autocomplete-filter-field.component';
import { StringModule } from '../string/string.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EntitiesSelectorModule } from './entities-selector/entities-selector.module';
import { SmartObjectAutocompleteComponent } from './smartobject-autocomplete/smartobject-autocomplete.component';
import { FolderAutocompleteComponent } from './folder-autocomplete/folder-autocomplete.component';
import { CustomerAutocompleteComponent } from './customer-autocomplete/customer-autocomplete.component';
import { CustomAutocompleteComponent } from './custom-autocomplete/custom-autocomplete.component';

@NgModule({
  declarations: [
    AutocompleteComponent,
    SystemAutocompleteComponent,
    ResourceAutocompleteComponent,
    AutocompleteOptionDirective,
    AutocompleteMultipleComponent,
    AutocompleteMultipleOptionDirective,
    AutocompleteMultipleItemDirective,
    AutocompleteChipsComponent,
    GroupChipsComponent,
    AutocompleteLabelChipsDirective,
    FeedAutocompleteComponent,
    SmartObjectAutocompleteComponent,
    BrandAutocompleteComponent,
    TemplateAutocompleteComponent,
    SystemAutocompleteFilterFieldComponent,
    FolderAutocompleteComponent,
    CustomerAutocompleteComponent,
    CustomAutocompleteComponent,
  ],
  imports: [
    GlobalModule,
    StringModule,
    ScrollingModule,
    EntitiesSelectorModule,
  ],
  exports: [
    AutocompleteComponent,
    SystemAutocompleteComponent,
    ResourceAutocompleteComponent,
    AutocompleteOptionDirective,
    AutocompleteMultipleComponent,
    AutocompleteMultipleOptionDirective,
    AutocompleteMultipleItemDirective,
    AutocompleteChipsComponent,
    GroupChipsComponent,
    AutocompleteLabelChipsDirective,
    FeedAutocompleteComponent,
    SmartObjectAutocompleteComponent,
    BrandAutocompleteComponent,
    TemplateAutocompleteComponent,
    SystemAutocompleteFilterFieldComponent,
    EntitiesSelectorModule,
    FolderAutocompleteComponent,
    CustomerAutocompleteComponent,
    CustomAutocompleteComponent,
  ]
})
export class AutocompleteModule { }
