import { Component, OnInit, Input, OnChanges, HostBinding } from '@angular/core';

/**
 *
 *
 * @export
 * @class NameExtComponent
 * @description Format filename by showing always the extention and truncating when needed a part of name
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-name-ext',
  templateUrl: './name-ext.component.html',
  styleUrls: ['./name-ext.component.scss']
})
export class NameExtComponent implements OnChanges {

  @Input() value: string;
  @Input() separator: string = '.';
  @Input() rtl: boolean = false;

  // @HostBinding('class.rtl') @Input() rtl: boolean = false;

  @HostBinding('class.ellipsis') ellipsis: boolean = true;

  @HostBinding('dir') get dir() {
    return this.rtl ? 'rtl' : 'ltr';
  }

  name: string;
  ext: string;

  constructor() { }

  ngOnChanges() {
    if (this.value) {
      const parts = this.value.split(this.separator);
      // console.log('parts', parts);
      if (parts.length > 1) {
        this.ext = this.separator + parts.pop();
        this.name = parts.join(this.separator);
      } else {
        this.name = parts[0];
        this.ext = '';
      }
    } else {
      this.name = '';
      this.ext = '';
    }

    // console.log(this.value, '-->', this.name, '--->', this.ext);
  }
}
