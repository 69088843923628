import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { System, SystemAlertInterface, SystemChunkInterface, SystemInterface, SystemScreenshotInteface, SystemWarningInterface } from 'app/models/system.model';
import { Observable, from, of } from 'rxjs';
import { TriggerInterface } from 'app/models/trigger.model';
import { BindInterface } from 'app/models/bind.model';
import { DistributionInterface } from 'app/models/distribution.model';
import { CircuitInterface } from 'app/models/circuit.model';
import { catchError, concatAll, first, map } from 'rxjs/operators';
import { GroupInterface } from 'app/models/group.model';
import { AdInterface } from 'app/models/ad.model';
import { OverrideInterface } from 'app/models/override.model';
import { SystemSnapshotInterface } from 'app/models/system-snapshot.model';
import { PlayerSettings } from 'app/modules/common/system/components/system-player-settings/system-player-settings.model';
import { PlaylistItemInterface } from 'app/modules/editorial/editorial.interface';
import { PaginatedResponse, PaginatedResponseInterface } from 'app/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class SystemRepositoryService {
  /**
   *Creates an instance of SystemRepositoryService.
   * @param {HttpClient} _http
   * @memberof SystemRepositoryService
   */
  constructor(private _http: HttpClient) { }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<SystemInterface>}
   * @memberof SystemRepositoryService
   */
  findAll(_params: HttpParams | any = {}): Observable<any> {

    // REDIRECT TO ANOTHER ENDPOINT
    if ('ids' in _params) {
      return this.getByIds(_params);
    }

    return this._http.get<any>('systems', {
      params: _params,
    });
  }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponseInterface<SystemInterface>>}
   * @memberof SystemRepositoryService
   */
  getByIds(_params: HttpParams | any = {}): Observable<PaginatedResponseInterface<SystemInterface>> {
    return this._http.get<SystemInterface[]>('systems/byid', {
      params: _params,
    }).pipe(
      map((items: SystemInterface[]) => {
        return new PaginatedResponse({
          pagination: {
            totalCount: items.length,
            current: 1,
            pageCount: 1,
          },
          items,
        });
      })
    );
  }

  /**
   *
   *
   * @returns {Observable<SystemInterface[]>}
   * @memberof SystemRepositoryService
   */
  fullSearch(q: string): Observable<any> {
    return this._http
      .get<any>('systems', {
        params: {
          count: '20',
          sorting: 'name', // TODO: why not "NAME"/"ASC" ?!
          direction: 'asc',
          filters: JSON.stringify([
            {
              field: 'name',
              type: 'search',
              value: q,
            },
          ]),
        },
      })
      .pipe(
        map((response): SystemInterface[] => {
          return response.items;
        }),
      );
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<SystemInterface[]>}
   * @memberof SystemRepositoryService
   */
  search(q: string): Observable<SystemInterface[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this._http.get<SystemInterface[]>(`systems/${encodeURIComponent(q.trim())}/search`);
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<SystemInterface[]>}
   * @memberof SystemRepositoryService
   */
  analyticsSearch(q: string): Observable<SystemInterface[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this._http.get<SystemInterface[]>(`systems/${encodeURIComponent(q.trim())}/search/analytcs`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<SystemInterface>}
   * @memberof SystemRepositoryService
   */
  generateSnapshot(id: string): Observable<SystemInterface> {
    return this._http.get<SystemInterface>(`api/plants/${id}`);
  }

  exportAll(_params: HttpParams | any = {}): Observable<System[]> {
    return this._http.get<any[]>('systems/export', {
      params: _params,
    });
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<SystemInterface>}
   * @memberof SystemRepositoryService
   */
  get(id: string): Observable<SystemInterface> {
    return this._http.get<SystemInterface>(`systems/${id}`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<TriggerInterface[]>}
   * @memberof SystemRepositoryService
   */
  getTriggers(system_id: string): Observable<TriggerInterface[]> {
    return this._http.get<TriggerInterface[]>(`systems/${system_id}/triggers`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<BindInterface[]>}
   * @memberof SystemRepositoryService
   */
  getBinds(system_id: string, type: string = 'origin'): Observable<BindInterface[]> {
    return this._http.get<BindInterface[]>(`systems/${system_id}/binds/${type}`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<DistributionInterface[]>}
   * @memberof SystemRepositoryService
   */
  getDistributions(system_id: string): Observable<DistributionInterface[]> {
    return this._http.get<DistributionInterface[]>(`distributions/${system_id}/system`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<CircuitInterface[]>}
   * @memberof SystemRepositoryService
   */
  getCircuits(system_id: string): Observable<CircuitInterface[]> {
    return this._http.get<CircuitInterface[]>(`circuits/${system_id}/system`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<AdInterface[]>}
   * @memberof SystemRepositoryService
   */
  getAds(system_id: string): Observable<AdInterface[]> {
    return this._http.get<AdInterface[]>(`ads/${system_id}/system`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<OverrideInterface[]>}
   * @memberof SystemRepositoryService
   */
  getOverrides(system_id: string): Observable<OverrideInterface[]> {
    return this._http.get<OverrideInterface[]>(`overrides/${system_id}/system`);
  }

  /**
   *
   *
   * @param {string} system_id
   * @returns {Observable<SystemSnapshotInterface[]>}
   * @memberof SystemRepositoryService
   */
  getSnapshots(system_id: string): Observable<SystemSnapshotInterface[]> {
    return this._http.get<SystemSnapshotInterface[]>(`systems/${system_id}/list/snapshot`);
    //return this._http.get<SystemSnapshotInterface[]>(`systems/${system_id}/info/snapshot`);
  }

  /**
   *
   *
   * @param {System} system
   * @returns {Observable<SystemAlertInterface[]>}
   * @memberof SystemRepositoryService
   */
  getAlerts(system_id: string): Observable<SystemAlertInterface[]> {
    return this._http.get<SystemAlertInterface[]>(`systems/${system_id}/info/alerts`);
  }

  /**
   *
   *
   * @param {System} system
   * @returns {Observable<any>}
   * @memberof SystemRepositoryService
   */
  getPlayerLogs(system_id: string): Observable<any> {
    return this._http.get<any[]>(`systems/${system_id}/player/logs`);
  }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<any>}
   * @memberof SystemRepositoryService
   */
  getPackages(_params: HttpParams | any = {}): Observable<System[]> {
    return this._http.get<any[]>('systems/ungrouped', {
      params: _params,
    });
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<SystemInterface>}
   * @memberof SystemRepositoryService
   */
  delete(id: string): Observable<SystemInterface> {
    return this._http.delete<SystemInterface>(`systems/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @param {SystemInterface} data
   * @returns {Observable<System>}
   * @memberof SystemRepositoryService
   */
  update(id: string, data: SystemInterface): Observable<any> {
    return this._http.put(`systems/${id}`, data);
  }

  getContents(id: string): Observable<SystemInterface> {
    return this._http.get<SystemInterface>(`systems/${id}/content`);
  }

  getOffline(params?: HttpParams | any): Observable<PaginatedResponse<SystemInterface>> {
    return this._http.get<PaginatedResponse<SystemInterface>>(`systems/offline`, {
      params,
    });
  }

  /**
   *
   *
   * @param {string} id
   * @param {SystemInterface} data
   * @returns {Observable<System>}
   * @memberof SystemRepositoryService
   */
  updateContents(id: string, data: SystemInterface): Observable<any> {
    return this._http.put(`systems/${id}/content`, data);
  }

  addPlaylistChunk(id: string, items: { item: PlaylistItemInterface, playlistId?: string }[], config: { chunksPerCall?: number; } = {}): Observable<SystemChunkInterface> {
    config = {
      chunksPerCall: 4,
      ...config,
    };

    const obs = new Observable<SystemChunkInterface>((subscriber) => {

      const nCalls = Math.ceil(items.length / config.chunksPerCall);
      const calls = [];
      for (let i = 0; i < nCalls; i++) {
        const isLast = i === (nCalls - 1);
        const fromIndex = i * config.chunksPerCall;
        const toIndex = fromIndex + config.chunksPerCall;
        const partials = items.slice(fromIndex, toIndex);

        // console.log('addChunk', i, fromIndex, toIndex, partials);

        // const call = this._http.put(`systems/${id}/addChunk`, {
        //   last: isLast,
        //   partials: partials,
        // })
        const call = this.search('taras')
          .pipe(
            map((data): SystemChunkInterface => {
              return {
                callIndex: i,
                totalCalls: nCalls,
                currentItems: fromIndex + partials.length,
                totalItems: items.length,
                partials,
                isLast,
              };
            }),
            // catchError((err) => {
            //   return of({
            //     callIndex: i,
            //     totalCalls: nCalls,
            //     currentItems: toIndex,
            //     totalItems: items.length,
            //     partials,
            //     isLast,
            //   });
            // })
          );
        calls.push(call);
      }

      from(calls).pipe(
        concatAll()
      ).subscribe((data: any) => {
        // console.log('addPlaylistChunk data', data);
        subscriber.next(data);
        if (data.isLast) {
          subscriber.complete();
        }
      }, (err) => {
        subscriber.error(err);
        subscriber.complete();
      });
    });
    return obs;
  }

  /**
   *
   *
   * @param {string} id
   * @param {SystemInterface} data
   * @returns {Observable<System>}
   * @memberof SystemRepositoryService
   */
  checkRecentUpdates(id: string, token: any): Observable<any> {
    return this._http.get(`systems/${id}/conflicts/${token}/token`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<System>}
   * @memberof SystemRepositoryService
   */
  create(data: SystemInterface): Observable<any> {
    return this._http.post(`systems`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface[]>}
   * @memberof SystemRepositoryService
   */
  getGroups(id: string): Observable<GroupInterface[]> {
    return this._http.get<GroupInterface[]>(`systems/${id}/groups`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof SystemRepositoryService
   */
  getOccupancy(id: string, from: string, to: string): Observable<any> {
    return this._http.get<any>(`systems/${id}/occupancy/period?start=${from}&end=${to}`);

    // https://api-dev-programmatic.fluidnext.com/systems/5ef0be2b2ea7374612193148/occupancy/period?start=2021-08-01&end=2021-08-31
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<{ groups: GroupInterface[] }>}
   * @memberof SystemRepositoryService
   */
  updateGroups(id: string, groups: GroupInterface[]): Observable<any> {
    return this._http.patch(`systems/${id}/groups`, { groups: groups });
  }

  getMetricsCPU(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/cpu-usage`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsMemory(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/mem-free`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsPower(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/power-monitor`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsTemperature(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/temp`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsBrightness(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/brightness`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsBacklight(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/backlight`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsNovaRecvCardsStatus(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/nova-rc-status`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsVoltage(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/voltage`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsVideoSignal(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/video-signal`, {
      params: {
        date: params.from,
      }
    });
  }

  getMetricsDiskSpace(params: { systemId: string, from: string }): Observable<any[]> {
    return this._http.get<any[]>(`metrics/${params.systemId}/disk-space`, {
      params: {
        date: params.from,
      }
    });
  }

  getWarnings(systemId: string): Observable<any[]> {
    return this._http.get<any>(`systemwarnings/${systemId}/system/opened`);
  }

  getWarningsHistory(systemId: string, _params: HttpParams | any = {}): Observable<PaginatedResponseInterface<SystemWarningInterface>> {
    return this._http.get<any>(`systemwarnings/${systemId}/system`, {
      params: _params,
    });
  }

  getAllWarnings(_params: HttpParams | any = {}): Observable<PaginatedResponseInterface<SystemWarningInterface>> {
    return this._http.get<any>(`systemwarnings`, {
      params: _params,
    });
  }

  connectToTv(params: {
    systemId: string;
    userId: string;
    code: string;
  }): Observable<any> {
    return this._http.patch(`link/process`, {
      code: params.code,
      sid: params.systemId,
      uid: params.userId
    });
  }

  disconnectFromTV(params: {
    systemId: string;
  }): Observable<any> {
    return this._http.patch(`link/unlink/${params.systemId}`, {});
  }

  getScreenshots(systemId: string): Observable<PaginatedResponseInterface<SystemScreenshotInteface>> {
    return this._http.get<PaginatedResponseInterface<SystemScreenshotInteface>>(`systemscreenshots/${systemId}/system`);
  }
}
