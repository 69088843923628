import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './core/auth/services/authguard.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routesProgrammatic: Routes = [

  // ADMIN TESTS
  {
    path: '_test',
    loadChildren: () => import('./modules/_test/_test.module').then((m) => m.TestModule),
    canLoad: [AuthGuard, NgxPermissionsGuard],
  },

  // DASHBOARD
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard, NgxPermissionsGuard],
  },

  // REMOTE CONTROL
  {
    path: 'remote-control',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToRemoteCtrlModule'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/remotecontrol/remotecontrol.module').then((m) => m.RemoteControlModule),
  },

  // CAMPAIGNS (ADS)
  {
    path: 'ads',
    loadChildren: () => import('./modules/ad/ad.module').then((m) => m.AdModule),
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToCommercialModule'], // TODO: check if works
        redirectTo: 'dashboard',
      },
    },
  },

  // CONTENT --> BRANDS
  {
    path: 'brands',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadUploadContentData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/brand/brand.module').then((m) => m.BrandModule),
  },

  // CONTENT --> FOLDERS
  {
    path: 'folders',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadUploadContentData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/folder/folder.module').then((m) => m.FolderModule),
  },

  // CONTENT --> SYSTEMS
  {
    path: 'systems',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadSystemsData'],
        redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/system/system.module').then((m) => m.SystemModule),
  },

  // CONTENT --> OVERRIDES
  {
    path: 'overrides',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadRulesMasterData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/override/override.module').then((m) => m.OverrideModule),
  },

  // CONTENT --> BINDS
  {
    path: 'binds',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadBindData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/bind/bind.module').then((m) => m.BindModule),
  },

  // CONTENT --> TRIGGERS
  {
    path: 'triggers',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadTriggersData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/trigger/trigger.module').then((m) => m.TriggerModule),
  },

  // CONTENT --> DISTRIBUTIONS
  {
    path: 'distributions',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadDistributionsData'], // TODO: check this
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/distribution/distribution.module').then((m) => m.DistributionModule),
  },

  // CONTENT --> CIRCUITS
  {
    path: 'circuits',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToContentModule', 'canReadCircuitsData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/circuit/circuit.module').then((m) => m.CircuitModule),
  },

  // DYNAMIC CONTENT --> DATASETS
  {
    path: 'datasets',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToDynamicContentModule', 'canReadDataSetsData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/dataset/dataset.module').then((m) => m.DatasetModule),
  },

  // DYNAMIC CONTENT --> TEMPLATES
  {
    path: 'templates',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToDynamicContentModule', 'canReadTemplateData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/template/template.module').then((m) => m.TemplateModule),
  },

  // DYNAMIC CONTENT --> SMARTOBJECTS
  {
    path: 'smartobjects',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToDynamicContentModule', 'canReadDContentData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/smartobject/smartobject.module').then((m) => m.SmartObjectModule),
  },

  // DYNAMIC CONTENT --> FEEDS
  {
    path: 'feeds',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToDynamicContentModule', 'canReadFeedsData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/feed/feed.module').then((m) => m.FeedModule),
  },

  // CUSTOMERS
  {
    path: 'customers',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToCustomersModule', 'canReadCustomerData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/customer/customer.module').then((m) => m.CustomerModule),
  },

  // CREDENTIALS --> GROUPS
  {
    path: 'groups',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToAuthModule', 'canAccessGroupsData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/group/group.module').then((m) => m.GroupModule),
  },

  // CREDENTIALS --> USERS
  {
    path: 'users',
    data: {
      permissions: {
        only: ['accessToAuthModule', 'canReadAuthData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    canLoad: [AuthGuard, NgxPermissionsGuard],
  },

  // FIRMWARES --> VERSIONS
  {
    path: 'firmwares',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToFirmwareModule', 'canReadFirmwareData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/firmware/firmware.module').then((m) => m.FirmwareModule),
  },

  // FIRMWARES --> RULES
  {
    path: 'firmwarerules',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToFirmwareModule', 'canReadFirmwareRuleData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/firmwarerule/firmwarerule.module').then((m) => m.FirmwareRuleModule),
  },


  // MONITOR --> LOGS
  {
    path: 'logs',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToMonitorModule', 'canReadLogData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/log/log.module').then((m) => m.LogModule),
  },

  // MONITOR --> LOGS EXPORTS
  {
    path: 'logexports',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToMonitorModule', 'canReadLogData'], // TODO: need its own permission?
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/export/export.module').then((m) => m.ExportModule),
  },

  // MONITOR --> STATUS
  {
    path: 'status',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToMonitorModule', 'canReadStatusSystemsData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/status/status.module').then((m) => m.StatusModule),
  },

  // MONITOR --> OVERVIEW
  {
    path: 'overview',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToMonitorModule', 'canReadSystemOverviewData'],
        //redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/overview/overview.module').then((m) => m.OverviewModule),
  },

  // MONITOR --> SYSTEMS OFFLINE
  {
    path: 'system-offline',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToMonitorModule'],
        redirectTo: 'dashboard',
      },
    },
    loadChildren: () => import('./modules/system-offline/system-offline.module').then((m) => m.SystemOfflineModule),
  },

  // COUNTER
  {
    path: 'counter',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    data: {
      permissions: {
        only: ['accessToCounterModule'],
        //redirectTo: 'dashboard',
      },
    },
    children: [
      {
        path: 'analytics',
        canLoad: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['accessToCounterModule', 'canReadAnalyticsData'],
          },
        },
        loadChildren: () => import('./modules/counter/pages/analytics/analytics.module').then((m) => m.AnalyticsModule),
      },
      {
        path: 'audiences',
        canLoad: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['accessToCounterModule', 'canReadAudienceData'],
          },
        },
        loadChildren: () => import('./modules/counter/pages/audience/audience.module').then((m) => m.AudienceModule),
      },
      {
        path: 'ots-stats',
        canLoad: [AuthGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['accessToCounterModule', 'canReadOtsData'],
          },
        },
        loadChildren: () => import('./modules/counter/pages/ots-stats/ots-stats.module').then((m) => m.OtsStatsModule),
      },
    ],
  },

  // HELP --> CHANGELOG
  {
    path: 'changelog',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    loadChildren: () => import('./modules/changelog/changelog.module').then((m) => m.ChangelogModule),
  },

  // OTHER --> PROFILE
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canLoad: [AuthGuard, NgxPermissionsGuard],
  },

  // OTHER --> NOTIFICATIONS
  {
    path: 'notifications',
    canLoad: [AuthGuard, NgxPermissionsGuard],
    loadChildren: () => import('./modules/notification/notification.module').then((m) => m.NotificationModule),
  },

  // GLOBAL --> PROFILE
  {
    path: 'forgot-password',
    redirectTo: 'auth/forgot-password',
  },

  // GLOBAL --> LOGIN
  { path: '**', redirectTo: 'auth/login' },

  // HIDDEN SECTIONS
  // {
  //   path: 'locations',
  //   loadChildren: () => import('./modules/location/location.module').then((m) => m.LocationModule),
  //   canLoad: [AuthGuard, NgxPermissionsGuard],
  // },

  // {
  //   path: 'packages',
  //   loadChildren: () => import('./modules/package/package.module').then(m => m.PackageModule),
  //   canLoad: [AuthGuard]
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routesProgrammatic, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
