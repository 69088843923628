import {
  Component,
  Input,
  EventEmitter,
  Output,
  forwardRef,
  OnChanges,
  ViewChild,
  AfterViewChecked,
  SimpleChanges,
  ChangeDetectorRef,
  Self,
  Optional,
  Injector,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  Validator,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgControl,
} from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRangeInterface } from '../dateslots/dateslot.interface';
import { I18nService } from 'app/services/i18n.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { startOfDay } from 'date-fns/esm';
import { isMoment, Moment } from 'moment';
import { DATEPICKER_FORMAT_INPUT, DATEPICKER_FORMAT_MONTH_YEAR } from 'app/modules/global/constants';

const datepickerFormatsFactory = (i18nService: I18nService) => {
  return {
    parse: {
      dateInput: i18nService.translate(DATEPICKER_FORMAT_INPUT),
    },
    display: {
      dateInput: i18nService.translate(DATEPICKER_FORMAT_INPUT),
      monthLabel: 'MMMM',
      monthYearLabel: i18nService.translate(DATEPICKER_FORMAT_MONTH_YEAR),
      // dateA11yLabel: 'LL', ???
      // monthYearA11yLabel: 'MMMM YYYY', ???
    },
  };;
}

/**
 *
 *
 * @export
 * @class DatepickerComponent
 * @description Datepicker component
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: datepickerFormatsFactory,
      deps: [
        I18nService,
      ],
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent implements OnInit, OnChanges, ControlValueAccessor, Validator, AfterViewChecked {
  @Input() set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  @Input() set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  protected _disabled = false;
  get disabled() {
    return this._disabled;
  }
  protected _required = false;
  get required() {
    return this._required;
  }

  @Input() prevalidate: boolean;
  @Input() placeholder: string = this._i18nService.translate(_('Data'));
  @Input() min: number | Date;
  @Input() max: number | Date;
  @Input() range: DateRangeInterface[];
  @Input() clearable: boolean = true;
  @Input() startView: string;
  @Input() hint: string;

  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild(MatInput) matInput: MatInput;

  pickerValue: Date;

  private _value;
  private _onChange = (event: any) => { };
  private _onTouched: any = () => { };

  registerOnChange(fn: (event: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(newValue) {
    if (typeof newValue !== 'undefined') {
      this._value = newValue;
      this.pickerValue = this.value;
      // console.log('datepicker changed', this.value, 'ngControl value', this.ngControl);
    }
  }

  constructor(
    // @Optional() @Self() public ngControl: NgControl,
    private _i18nService: I18nService,
    private _cdr: ChangeDetectorRef,
    private injector: Injector,
  ) {
    // if (this.ngControl != null) {
    //   this.ngControl.valueAccessor = this;
    //   // console.log('ngControl', this.ngControl);
    // }
  }

  ngControl: NgControl;

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    // console.log('ngControl', this.ngControl);
  }


  ngAfterViewChecked() {
    if (!!this.formControl && !!this.matInput && !!this.matInput.ngControl && !!this.matInput.ngControl.control) {
      if (this.formControl.touched && !this.matInput.ngControl.control.touched) {
        setTimeout(() => {
          this.matInput.ngControl.control.markAsTouched();
        });
      }
    }
  }

  get formControl() {
    return (this.ngControl as any).control;
  }

  blur(event) {
    this.formControl?.markAsTouched();
  }

  set value(value) {

    if (isMoment(value)) {
      value = value.toDate();
    }

    // console.log('set DP value', value);

    this.writeValue(value);
    this._onChange(value);

    // console.log('value changed!', value);
    // console.log('ngControl!', this.ngControl);
    this.dateChange.emit(value);
  }

  get value() {
    return this._value;
  }

  minError(min) {
    return min && this.value && new Date(this.value).getTime() < new Date(min).getTime();
  }

  maxError(max) {
    return max && this.value && new Date(this.value).getTime() > new Date(max).getTime();
  }

  validate(c: FormControl) {
    // this.ngControl = c;

    // custom errors
    const err: { required?: boolean; min?: Date; max?: Date; outOfRange?: boolean } = {};
    if (this.required && !this._value) {
      err.required = true;
    }

    if (!this.range) {
      // if (this.min && this.value && new Date(this.value).getTime() < new Date(this.min).getTime()) {
      if (this.minError(this.min)) {
        err.min = new Date(this.min);
      }
      // if (this.max && this.value && new Date(this.value).getTime() > new Date(this.max).getTime()) {
      if (this.maxError(this.max)) {
        err.max = new Date(this.max);
      }
    } else {
      let outOfRange = true;
      for (let i = 0; i < this.range.length; i++) {
        const item = this.range[i];
        if (!this.minError(item.startAt) && !this.maxError(item.endAt)) {
          outOfRange = false;
          break;
        }
      }
      if (outOfRange) {
        err.outOfRange = true;
      }
    }

    // console.log('errs are', err, 'status?', this.ngControl.status);

    if (Object.keys(err).length) {
      return err;
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('datepicker changes', changes);
    if ((changes.min && !changes.min.firstChange) || (changes.max && !changes.max.firstChange) || (changes.disabled && !changes.disabled.firstChange) || (changes.required && !changes.required.firstChange)) {
      if (this.formControl) {
        this.validate(this.formControl);
        this._cdr.detectChanges();
        // this.formControl.updateValueAndValidity({
        //   emitEvent: false,
        // });
      }
    }
  }

  dateChangeEvent(value: Moment | Date) {
    console.log('dateChangeEvent value', value);

    if (!!value) {
      if (isMoment(value)) {
        value = value.toDate();
      }
      console.log('current', this.value, 'incoming', value);
      if (!this.value || this.value.toString() !== value.toString()) {
        this.value = startOfDay(value);
      } else {
        console.log('same date, ignore');
      }
    } else {
      this.value = null;
    }
  }
}
