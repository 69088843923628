<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <app-icon icon="menu" class="secondary-text"></app-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/fuse.svg" />
        </div>
      </div>
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

      <!-- LOCAL INSTALLATION && NOT DEV ENV WARNING -->
      <div class="mx-8">
        <span *ngIf="localAndNotDev" class="not-dev-warn">
          NOT DEV
        </span>
      </div>

      <!-- FAVORITES -->
      <div class="mx-8">
        <button [matTooltip]="'Preferiti' | translate" mat-icon-button [matMenuTriggerFor]="favoritesMenu">
          <app-icon icon="is_favorite"></app-icon>
        </button>
        <mat-menu #favoritesMenu="matMenu">
          <div mat-menu-item [disableRipple]="true" *ngFor="let system of favorites; let i = index">
            <div fxLayout="row" fxLayoutAlign="flex-start center" class="ellipsis">
              <app-monitor-status-icon [monitor]="system.monitor"></app-monitor-status-icon>
              <div fxFlex class="mr-8 ellipsis" [matTooltip]="system.name">
                <!-- lh1 word-break-all -->
                {{ system.name }}
              </div>
              <button mat-icon-button (click)="removeFavorite($event, system)" class="app-icon-btn-sm">
                <mat-icon color="warn">delete_outline</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="!favorites.length" mat-menu-item [disableRipple]="true" class="grey-400-fg">
            {{ 'nessun impianto preferito' | translate }}
          </div>
        </mat-menu>
      </div>

      <!-- NOTIFICATIONS -->
      <!-- <div *ngIf="false" class="mx-8"> -->
      <div class="mx-8">
        <app-notification-button></app-notification-button>
        <!-- <button [matBadge]="'2'" [matTooltip]="'Notifiche' | translate" mat-icon-button [matMenuTriggerFor]="notificationsMenu">
          <app-icon icon="is_notification"></app-icon>
        </button>
        <mat-menu #notificationsMenu="matMenu">
          <div mat-menu-item [disableRipple]="true" *ngFor="let notification of []; let i = index">
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="flex-start center">
              <div class="ellipsis" fxFlex>
                {{ notification.subject }}
              </div>
            </div>
          </div>
          <div
            *ngIf="!favorites.length"
            mat-menu-item
            [disableRipple]="true"
            class="grey-400-fg"
          >
            {{ 'nessuna notifica recente' | translate }}
          </div>
          <div mat-menu-item [routerLink]="'/notifications'">
            <span>{{ 'Mostra tutte' | translate }}</span>
          </div>
        </mat-menu> -->
      </div>

      <!-- USER BOX -->
      <div fxLayout="row" *ngIf="currentUser?.currentUser">
        <div class="toolbar-separator"></div>
        <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
          <div fxLayout="row" fxLayoutAlign="center center">
            <img class="avatar mr-0 mr-sm-16" [src]="currentUser.getAvatarUrl()" />
            <mat-icon *ngIf="currentUser?.isImpersonatedUser()" fxHide.gt-sm
              class="user-impersonate-icon red-400-fg">supervised_user_circle</mat-icon>
            <div class="text-left" fxLayout="column" fxHide.lt-md>
              <small *ngIf="currentUser?.isImpersonatedUser()" class="red-400-fg">{{
                'loggato come' | translate
              }}</small>
              <span class="username mr-12">{{ currentUser.getAnyName() }}</span>
            </div>
            <app-icon icon="caret_down" class="s-16" fxHide.lt-md></app-icon>
          </div>
        </button>
        <mat-menu class="user-box-menu" #userMenu="matMenu" [overlapTrigger]="false"
          backdropClass="user-box-menu-overlay">

          <div class="grey-200-bg p-8 mx-8">

            <div class="mb-4" fxLayout="row" fxLayoutAlign="flex-start center">
              <mat-icon class="mr-4 app-icon-btn-sm">security</mat-icon>
              <small>
                <strong>
                  {{rolesLabel}}
                </strong>
              </small>
            </div>

            <ng-container *ngIf="groupsLabel">
              <small class="app-input-label">{{ 'Gruppi' | translate }}</small>
              <div>
                <small>
                  <strong>
                    {{ groupsLabel }}
                  </strong>
                </small>
              </div>
            </ng-container>

          </div>

          <div *ngIf="isAdminTaras" class="mb-12">
            <button class="w-100-p" mat-button (click)="impersonateX1()">Impersonate SB x1</button>
            <button class="w-100-p" mat-button (click)="impersonateX2()">Impersonate SB x2</button>
          </div>

          <!-- PROFILE -->
          <button mat-menu-item (click)="myProfile()">
            <app-icon icon="profile"></app-icon>
            <span>{{ 'Profilo' | translate }}</span>
          </button>

          <!-- TESTS PAGE -->
          <button *ngIf="isAdmin" mat-menu-item [routerLink]="'/_test/pages/fxlayout'">
            <mat-icon class="red-400-fg">cruelty_free</mat-icon>
            <span>{{ 'Dev Playground' | translate }}</span>
          </button>

          <!-- LANGUAGES -->
          <ng-container *ngIf="languages?.length > 0 && currentLanguage">
            <button mat-menu-item class="language-button user-box-menu-item-multiline"
              [matMenuTriggerFor]="languageMenu">
              <div fxLayout="row" fxLayoutAlign="flex-start center">
                <div class="language-button-image-wrapper">
                  <img class="flag" [src]="currentLanguage.imagePath" />
                </div>
                <div class="text-left" fxLayout="column">
                  <small class="grey-400-fg">{{ 'Lingua' | translate }}</small>
                  <span class="iso text-uppercase">{{ currentLanguage.code }}</span>
                </div>
              </div>
            </button>
            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
              <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                <span fxLayout="row" fxLayoutAlign="start center">
                  <img class="flag mr-16" [src]="lang.imagePath" />
                  <span class="iso">{{ lang.name }}</span>
                </span>
              </button>
            </mat-menu>
          </ng-container>

          <!-- SETTINGS -->
          <ng-container *ngIf="isAdmin">
            <button mat-menu-item [matMenuTriggerFor]="settingsMenu">
              <mat-icon class="blue-400-fg">settings</mat-icon>
              <span>{{ 'Impostazioni' | translate }}</span>
            </button>
            <mat-menu #settingsMenu="matMenu" [overlapTrigger]="false">

              <!-- TIME FORMAT -->
              <ng-container>
                <button mat-menu-item [matMenuTriggerFor]="settingsTimeFormatMenu">
                  <mat-icon class="blue-400-fg">schedule</mat-icon>
                  <span>{{ 'Formato ora' | translate }}</span>
                </button>
                <mat-menu #settingsTimeFormatMenu="matMenu" [overlapTrigger]="false">
                  <button mat-menu-item *ngFor="let format of TimepickerFormatList"
                    (click)="setTimeFormat(format.value)">
                    <mat-icon
                      [ngClass]="{ 'green-600-fg': currentTimeFormat === format.value, 'grey-50-fg': currentTimeFormat !== format.value }">check</mat-icon>
                    {{format.label}}
                  </button>
                </mat-menu>
              </ng-container>

            </mat-menu>
          </ng-container>

          <!-- BACK TO ADMIN -->
          <button *ngIf="currentUser?.isImpersonatedUser()" mat-menu-item (click)="logoutToAdminUser()"
            class="user-box-menu-item-multiline">
            <div fxLayout="row" fxLayoutAlign="flex-start center">
              <app-icon icon="logout"></app-icon>
              <strong class="text-left" fxLayout="column">
                <small class="red-400-fg">{{ 'torna a' | translate }}</small>
                <span>{{ currentUser.getMasterUser()?.fullname }}</span>
              </strong>
            </div>
          </button>
          <!-- LOGOUT -->
          <button *ngIf="!currentUser?.isImpersonatedUser()" mat-menu-item (click)="logout()">
            <app-icon icon="logout"></app-icon>
            <span>{{ 'Esci' | translate }}</span>
          </button>
        </mat-menu>
      </div>

      <!--
            SEARCH
            <div class="toolbar-separator"></div>
            <fuse-search-bar (input)="search($event)"></fuse-search-bar>
            -->

      <!--
            RIGHT SIDEBAR
            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>
            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
            -->
    </div>
  </div>
</mat-toolbar>