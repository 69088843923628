import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CircuitInterface } from 'app/models/circuit.model';
import { SystemInterface } from 'app/models/system.model';
import { GroupInterface } from 'app/models/group.model';
import { PaginatedResponse, PaginatedResponseInterface } from 'app/models/pagination.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CircuitRepositoryService {
  /**
   *Creates an instance of CircuitRepositoryService.
   * @param {HttpClient} _http
   * @memberof CircuitRepositoryService
   */
  constructor(private _http: HttpClient) { }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<CircuitInterface[]>}
   * @memberof CircuitRepositoryService
   */
  findAll(_params: HttpParams | any = {}): Observable<PaginatedResponse<CircuitInterface>> {

    // REDIRECT TO ANOTHER ENDPOINT
    if ('ids' in _params) {
      return this.getByIds(_params);
    }

    return this._http.get<PaginatedResponse<CircuitInterface>>('circuits', {
      params: _params,
    });
  }

  /**
   *
   *
   * @param {*} [_params={}]
   * @returns {Observable<PaginatedResponseInterface<CircuitInterface>>}
   * @memberof SystemRepositoryService
   */
  getByIds(_params: HttpParams | any = {}): Observable<PaginatedResponseInterface<CircuitInterface>> {
    return this._http.get<CircuitInterface[]>('circuits/byid', {
      params: _params,
    }).pipe(
      map((items: CircuitInterface[]) => {
        return new PaginatedResponse({
          pagination: {
            totalCount: items.length,
            current: 1,
            pageCount: 1,
          },
          items,
        });
      })
    );
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<CircuitInterface[]>}
   * @memberof CircuitRepositoryService
   */
  get(id: string): Observable<CircuitInterface> {
    return this._http.get<CircuitInterface>(`circuits/${id}`);
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<CircuitInterface[]>}
   * @memberof CircuitRepositoryService
   */
  search(q: string): Observable<CircuitInterface[]> {
    if (typeof q !== 'string' || !q.trim()) return of([]);
    return this._http.get<CircuitInterface[]>(`circuits/${encodeURIComponent(q.trim())}/search`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<CircuitInterface>}
   * @memberof CircuitRepositoryService
   */

  delete(id: string): Observable<CircuitInterface> {
    return this._http.delete<CircuitInterface>(`circuits/${id}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<CircuitInterface>}
   * @memberof CircuitRepositoryService
   */

  update(id: string, data: CircuitInterface): Observable<any> {
    return this._http.put(`circuits/${id}`, data);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<CircuitInterface>}
   * @memberof CircuitRepositoryService
   */
  create(data: CircuitInterface): Observable<any> {
    return this._http.post(`circuits`, data);
  }

  /**
   *
   *
   * @param {string} q
   * @returns {Observable<SystemInterface[]>}
   * @memberof CircuitRepositoryService
   */
  getSystemsByIds(q: string[]): Observable<SystemInterface[]> {
    return this._http.get<SystemInterface[]>(`circuits/systems?c=${encodeURIComponent(q.toString())}`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<GroupInterface[]>}
   * @memberof CircuitRepositoryService
   */
  getGroups(id: string): Observable<GroupInterface[]> {
    return this._http.get<GroupInterface[]>(`circuits/${id}/groups`);
  }

  /**
   *
   *
   * @param {string} id
   * @returns {Observable<{ groups: GroupInterface[] }>}
   * @memberof CircuitRepositoryService
   */
  updateGroups(id: string, groups: GroupInterface[]): Observable<any> {
    return this._http.patch(`circuits/${id}/groups`, { groups: groups });
  }
}
