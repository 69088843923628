import { Group } from 'app/models/group.model';
import { UserRole } from 'app/models/user-role';
import { Grant } from '../core/auth/models/grant.model';
import { Permission } from '../core/auth/models/permission.model';
import { Resource } from './resource.model';
import { CustomerInterface } from './customer.model';

export interface UserInterface {
  id?: string;
  customers?: CustomerInterface[];
  firstName?: string;
  lastName?: string;
  username?: string;
  email?: string;
  enabled?: boolean;
  groups?: Group[];
  created_at?: Date;
  updated_at?: Date;
  password?: string;
  password_confirm?: string;
  roles?: UserRole[];
  grants?: Grant[];
  fullname?: string;
  permissions?: Permission[];
  role_permissions?: Permission[];
  avatar?: Resource;
}

export class User implements UserInterface {
  id: string;
  customers: CustomerInterface[] = [];
  firstName: string;
  lastName: string;
  username: string;
  fullname: string;
  email: string;
  enabled: boolean;
  roles: UserRole[] = [];
  groups: Group[] = [];
  created_at: Date;
  updated_at: Date;
  password: string;
  password_confirm: string;
  grants: Grant[] = [];
  permissions: Permission[] = [];
  role_permissions: Permission[] = [];
  avatar: Resource;

  /**
   * Constructor
   *
   * @param _user
   */
  constructor(private _user?: UserInterface) {
    Object.assign(this, this._user);
    delete this._user;
  }
}

export interface RoleInterface {
  label: string;
  value: string;
}

export interface UserSettingsInterface {
  id: string;
  created_at: Date;
  updated_at: Date;
  user: UserInterface;
  notifications: UserSettingsNotificationsInterface;
  expireAt?: Date | null;
}

export class UserSettings implements UserSettingsInterface {
  id: string;
  created_at: Date;
  updated_at: Date;
  user: UserInterface;
  expireAt: Date | null;
  notifications: UserSettingsNotificationsInterface;
  /**
   * Constructor
   *
   * @param _conf
   */
  constructor(private _conf?: UserSettingsInterface) {
    Object.assign(this, this._conf);
    this.notifications = new UserSettingsNotifications(this.notifications);
    delete this._conf;
  }
}

export interface UserSettingsNotificationsInterface {
  notReachable: boolean;
}

export class UserSettingsNotifications implements UserSettingsNotificationsInterface {
  notReachable: boolean = false;

  /**
   * Constructor
   *
   * @param _conf
   */
  constructor(private _conf?: UserSettingsNotificationsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}
