import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nService } from 'app/services/i18n.service';
import { HintService } from 'app/modules/common/hint/hint.service';
import { SentryService } from 'app/services/sentry.service';
import { environment } from 'environments/environment';

export const navigation: (config: {
  I18nService: I18nService;
  HintService: HintService;
  SentryService: SentryService;
}) => FuseNavigation[] = (config) => {
  const items: FuseNavigation[] = [
    {
      id: 'applications',
      title: '',
      type: 'group',
      children: [
        {
          id: 'dashboard-section',
          title: 'Bacheca',
          translate: _('Bacheca'),
          type: 'item',
          icon: 'home',
          url: '/dashboard',
        },
        {
          id: 'remote-control-section',
          title: 'Controllo LIVE',
          translate: _('Controllo LIVE'),
          // translate: 'NAV.DASHBOARD.TITLE',
          type: 'item',
          icon: 'gamepad',
          url: '/remote-control',
        },
        {
          id: 'ads-section',
          title: 'Campagne',
          translate: _('Campagne'),
          type: 'collapsable',
          icon: 'business',
          children: [
            {
              id: 'ads_programmed',
              title: 'Programmate',
              translate: _('Programmate'),
              icon: 'event_available',
              type: 'item',
              url: '/ads/programmed',
            },
            {
              id: 'ads_ended',
              title: 'Concluse',
              translate: _('Concluse'),
              icon: 'event_busy',
              type: 'item',
              url: '/ads/ended',
            },
            {
              id: 'ads_suspended',
              title: 'Sospese',
              translate: _('Sospese'),
              icon: 'pending_actions',
              type: 'item',
              url: '/ads/suspended',
            },
            {
              id: 'ads_all',
              title: 'Tutte',
              translate: _('Tutte'),
              icon: 'format_list_numbered_rtl',
              type: 'item',
              url: '/ads/all',
            },
          ],
        },
        {
          id: 'brands-section',
          title: 'Contenuti',
          translate: _('Contenuti'),
          type: 'collapsable',
          icon: 'inbox',
          children: [
            {
              id: 'brands',
              title: 'Caricamento',
              translate: _('Caricamento'),
              icon: 'backup',
              type: 'item',
              url: '/brands',
            },
            {
              id: 'folders',
              title: 'Risorse',
              translate: _('Risorse'),
              icon: 'folder_copy',
              type: 'item',
              url: '/folders',
            },
            {
              id: 'systems',
              title: 'Impianti',
              translate: _('Impianti'),
              icon: 'desktop_windows',
              type: 'item',
              url: '/systems',
            },
            {
              id: 'overrides',
              title: 'Regole Master',
              translate: _('Regole Master'),
              icon: 'account_balance',
              type: 'item',
              url: '/overrides',
            },
            {
              id: 'binds',
              title: 'Bind',
              translate: _('Bind'),
              icon: 'swap_horiz',
              type: 'item',
              url: '/binds',
            },
            {
              id: 'triggers',
              title: 'Triggers',
              translate: _('Triggers'),
              icon: 'fast_forward',
              type: 'item',
              url: '/triggers',
            },
            {
              id: 'distributions',
              title: 'Distribuzione',
              translate: _('Distribuzione'),
              icon: 'storage',
              type: 'item',
              url: '/distributions',
            },
            {
              id: 'circuits',
              title: 'Circuiti',
              translate: _('Circuiti'),
              icon: 'data_usage',
              type: 'item',
              url: '/circuits',
            },
          ],
        },
        {
          id: 'templates-section',
          title: 'Cont. dinamici',
          translate: _('Cont. dinamici'),
          type: 'collapsable',
          icon: 'move_to_inbox',
          children: [
            {
              id: 'datasets',
              title: 'Set di dati',
              translate: _('Set di dati'),
              icon: 'unarchive',
              type: 'item',
              url: '/datasets',
            },
            {
              id: 'templates',
              title: 'Template',
              translate: _('Template'),
              icon: 'view_array',
              type: 'item',
              url: '/templates',
            },
            {
              id: 'smartobjects',
              title: 'Smart Objects',
              translate: _('Smart Objects'),
              type: 'item',
              icon: 'bug_report',
              url: '/smartobjects',
            },
            {
              id: 'feeds',
              title: 'Feeds',
              translate: _('Feeds'),
              type: 'item',
              icon: 'rss_feed',
              url: '/feeds',
            },
          ],
        },
        {
          id: 'customers-section',
          title: 'Clienti',
          translate: _('Clienti'),
          type: 'item',
          icon: 'supervisor_account',
          url: '/customers',
        },
        {
          id: 'credentials-section',
          title: 'Credenziali',
          translate: _('Credenziali'),
          type: 'collapsable',
          icon: 'security',
          children: [
            {
              id: 'groups',
              title: 'Gruppi',
              translate: _('Gruppi'),
              icon: 'group',
              type: 'item',
              url: '/groups',
            },
            {
              id: 'users',
              title: 'Utenti',
              translate: _('Utenti'),
              icon: 'person',
              type: 'item',
              url: '/users',
            },
          ],
        },
        {
          id: 'firmwares-section',
          title: 'Firmwares',
          translate: _('Firmwares'),
          type: 'collapsable',
          icon: 'sd_storage',
          children: [
            {
              id: 'firmwares',
              title: 'Versioni',
              translate: _('Versioni'),
              icon: 'sd_storage',
              type: 'item',
              url: '/firmwares',
            },
            {
              id: 'firmwarerules',
              title: 'Regole',
              translate: _('Regole'),
              icon: 'sd_storage',
              type: 'item',
              url: '/firmwarerules',
            },
          ],
        },
        {
          id: 'monitor-section',
          title: 'Monitor',
          translate: _('Monitor'),
          type: 'collapsable',
          icon: 'tv',
          children: [
            {
              id: 'logs',
              title: 'Log',
              translate: _('Log'),
              type: 'item',
              icon: 'bar_chart',
              url: '/logs',
            },
            {
              id: 'logexport',
              title: 'Export',
              translate: _('Export'),
              type: 'item',
              icon: 'ios_share',
              url: '/logexports',
            },
            {
              id: 'status',
              title: 'Stato impianti',
              translate: _('Stato impianti'),
              type: 'item',
              icon: 'power',
              url: '/status',
            },
            {
              id: 'system-overview',
              title: 'Panoramica Impianti',
              translate: _('Panoramica Impianti'),
              type: 'item',
              icon: 'center_focus_strong',
              url: '/overview',
            },
            {
              id: 'system-offline',
              title: 'Impianti Offline',
              translate: _('Impianti Offline'),
              type: 'item',
              icon: 'tv_off',
              url: '/system-offline',
            },
          ],
        },
        {
          id: 'counter-section',
          title: 'Counter',
          translate: _('Counter@@Rimane Counter in tutte le lingue'),
          type: 'collapsable',
          icon: 'data_exploration',
          children: [

            {
              id: 'audience',
              title: 'Audience',
              translate: _('Audience'),
              type: 'item',
              icon: 'preview',
              url: '/counter/audiences',
            },
            {
              id: 'analytics',
              title: 'Analytics',
              translate: _('Analytics'),
              type: 'item',
              icon: 'ssid_chart',
              url: '/counter/analytics',
            },
            {
              id: 'ots-stats',
              title: 'OTS',
              translate: _('OTS'),
              type: 'item',
              icon: 'diversity_3',
              url: '/counter/ots-stats',
            },



          ],
        },
      ],
    },
    {
      id: 'support-section',
      title: 'Supporto',
      translate: _('Supporto'),
      type: 'group',
      children: [
        {
          id: 'openticket',
          title: 'Apri un Ticket',
          translate: _('Apri un Ticket'),
          type: 'item',
          icon: 'support',
          url: 'https://helpdesk.fluidnext.com/support/tickets/new',
          externalUrl: true,
          openInNewTab: true,
        },
        {
          id: 'changelog-section',
          title: 'Changelog',
          translate: _('Changelog'),
          type: 'item',
          icon: 'history_edu',
          url: '/changelog',
          badge: {
            title: environment.changelog,
            // translate: _('1.8.0'),
          },
        },
        // HELP
        navigationToggleItem(
          {
            id: 'help-hints',
            title: 'Help Center',
            translate: _('Help Center'),
            type: 'item',
            icon: 'support_agent',
            value: () => config.HintService.enabled,
            toggleFn: () => {
              config.HintService.toggle();
            },
          },
          { I18nService: config.I18nService },
        ),
        {
          id: 'usermanual-section',
          title: 'Manuale di utilizzo',
          translate: _('Manuale di utilizzo'),
          type: 'item',
          icon: 'source',
          url: config.I18nService.currentLanguageCode === 'it' ? '/assets/docs/Manuale_1.15.1.pdf' : '/assets/docs/UserManual_1.15.1.pdf',
          openInNewTab: true,
          badge: {
            title: '1.15.1',
          },
        },
        // END HELP
        // SENTRY DIALOG
        // environment.sentry
        //   ? navigationToggleItem(
        //       {
        //         id: 'sentry-dialog',
        //         title: 'Feedback Errori',
        //         translate: _('Feedback Errori'),
        //         type: 'item',
        //         icon: 'feedback',
        //         value: () => config.SentryService.showDialog,
        //         toggleFn: () => {
        //           config.SentryService.toggleDialog();
        //         },
        //       },
        //       { I18nService: config.I18nService },
        //     )
        //   : undefined,
        // END SENTRY DIALOG
      ],
    },
    // {
    //   id: 'settings',
    //   title: 'Impostazioni',
    //   translate: _('Impostazioni'),
    //   type: 'group',
    //   children: [
    //     // HELP
    //     navigationToggleItem(
    //       {
    //         id: 'help-hints',
    //         title: 'Help Center',
    //         translate: _('Help Center'),
    //         type: 'item',
    //         icon: 'help',
    //         value: () => config.HintService.enabled,
    //         toggleFn: () => {
    //           config.HintService.toggle();
    //         },
    //       },
    //       { I18nService: config.I18nService },
    //     ),
    //     // END HELP
    //     // SENTRY DIALOG
    //     // environment.sentry
    //     //   ? navigationToggleItem(
    //     //       {
    //     //         id: 'sentry-dialog',
    //     //         title: 'Feedback Errori',
    //     //         translate: _('Feedback Errori'),
    //     //         type: 'item',
    //     //         icon: 'feedback',
    //     //         value: () => config.SentryService.showDialog,
    //     //         toggleFn: () => {
    //     //           config.SentryService.toggleDialog();
    //     //         },
    //     //       },
    //     //       { I18nService: config.I18nService },
    //     //     )
    //     //   : undefined,
    //     // END SENTRY DIALOG
    //   ],
    // },
  ];

  // console.log('Menu is', items);

  return items;
};

export interface FuseNavigationToggleItem extends FuseNavigationItem {
  value?: () => any;
  toggleFn?: () => void;
}

export const navigationToggleItem = (navItem: FuseNavigationToggleItem, config): FuseNavigationItem => {
  navItem.classes = navItem.value() ? '' : 'grey-400-fg';
  navItem.badge = navItem.value() ? hintBadgeOn : hintBadgeOff;

  navItem.function = function () {
    if (typeof navItem.toggleFn === 'function') {
      navItem.toggleFn();
    }
    if (navItem.value()) {
      navItem.badge = hintBadgeOn;
      navItem.badge.title = config.I18nService.translate(_('ON'));
      navItem.classes = '';
    } else {
      navItem.badge = hintBadgeOff;
      navItem.badge.title = config.I18nService.translate(_('OFF'));
      navItem.classes = 'grey-400-fg';
    }
  };

  return navItem;
};

export const hintBadgeOn = {
  title: 'ON',
  translate: _('ON'),
  bg: '#66bb6a',
  fg: undefined,
};

export const hintBadgeOff = {
  title: 'OFF',
  translate: _('OFF'),
  bg: '#1e212a',
  fg: '#aaaaaa',
};
