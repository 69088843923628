import { Coordinates, CoordinatesInterface } from 'app/models/coordinates.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MonthIndexInterface, WeekdayInterface } from 'app/modules/common/datetime/datetime.interface';
import { startOfDay } from 'date-fns';

export interface PlayerSettingsInterface {
  notWaitingEndCurrentVideo?: boolean;
  playerRemoteEnabled?: boolean;
  brightnessDefault?: number;
  udp?: PlayerSettingsUDPInterface;
  sender?: PlayerSettingsSenderInterface;
  transition?: PlayerSettingsTransitionInterface;
  common?: PlayerSettingsCommonInterface;
  logs?: PlayerSettingsLogsInterface;
  screenshots?: PlayerSettingsScreenshotsInterface;
  displayControl?: PlayerSettingsDisplayControlInterface;
  audioControl?: PlayerSettingsAudioControlInterface;
  rebootHardware?: PlayerSettingsRebootHardwareInterface;
  performance?: PlayerSettingsPerformanceInterface;
}

export interface PlayerSettingsPerformanceInterface {
  enabled?: boolean;
  cpuLimit?: number;
  ramLimit?: number;
}

export class PlayerSettingsPerformance implements PlayerSettingsPerformanceInterface {
  enabled: boolean = true;
  cpuLimit: number = 85;
  ramLimit: number = 85;

  constructor(private _conf?: PlayerSettingsPerformanceInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

// Display Control
export interface DisplayControlConfigItemInterface {
  id: string;
  name: string;
  noIp?: boolean;
  defaultIp?: string;
  hasPorts?: boolean;
  defaultSenderPort?: number;
  defaultReceiverPort?: number;
  hasBroadcasting?: boolean;
  defaultBroadcasting?: boolean;
}

export class DisplayControlConfigItem {
  id: string;
  name: string;
  noIp?: boolean = false;
  defaultIp?: string = '';
  hasPorts?: boolean = false;
  defaultSenderPort?: number = null;
  defaultReceiverPort?: number = null;
  hasBroadcasting?: boolean = false;
  defaultBroadcasting?: boolean = false;

  constructor(private _conf: DisplayControlConfigItemInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }

}

export const DisplayControlNames: DisplayControlConfigItem[] = [
  new DisplayControlConfigItem({
    id: 'samsung',
    name: 'Samsung',
  }),
  new DisplayControlConfigItem({
    id: 'lg-tecnovision',
    name: 'LG-Tecnovision'
  }),
  new DisplayControlConfigItem({
    id: 'nova',
    name: 'NOVA',
    hasPorts: true,
    defaultIp: '127.0.0.1',
    defaultSenderPort: 11001,
    defaultReceiverPort: 11000,
    hasBroadcasting: true,
  }),
  new DisplayControlConfigItem({
    id: 'lg',
    name: 'LG'
  }),
  new DisplayControlConfigItem({
    id: 'webos',
    name: 'WebOS',
    noIp: true,
  })].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

export const DisplayControlPorts = [{
  id: 'DisplayPort_1',
  name: 'DisplayPort_1'
}, {
  id: 'HDMI_1',
  name: 'HDMI_1'
}, {
  id: 'HDMI_2',
  name: 'HDMI_2'
}, {
  id: 'DVI',
  name: 'DVI'
}, {
  id: 'MagicInfo',
  name: 'MagicInfo'
},].sort((a, b) => {
  return a.name.localeCompare(b.name);
});

export interface PlayerSettingsDisplayControlInterface {
  enabled?: boolean;
  collection?: PlayerSettingsDisplayControlCollectionInterface[];
}

export interface PlayerSettingsDisplayControlCollectionInterface {
  model?: string;
  ip?: string;
  senderPort?: number;
  receiverPort?: number;
  broadcasting?: boolean;
}

export class PlayerSettingsDisplayControlCollection implements PlayerSettingsDisplayControlCollectionInterface {
  model?: string = DisplayControlNames[0].id;
  ip?: string = '';
  senderPort?: number;
  receiverPort?: number;
  broadcasting?: boolean;

  constructor(private _conf?: PlayerSettingsDisplayControlCollectionInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsDisplayControl implements PlayerSettingsDisplayControlInterface {
  enabled: boolean = false;
  collection?: PlayerSettingsDisplayControlCollectionInterface[] = [];

  constructor(private _conf?: PlayerSettingsDisplayControlInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

// Audio Control
export enum AudioControlEnum {
  ros_and_ros = 'ros_and_ros',
}

export const AudioControlNames: { id: AudioControlEnum, name: string }[] = [
  {
    id: AudioControlEnum.ros_and_ros,
    name: _('Ros & Ros'),
  }
];

export interface PlayerSettingsAudioControlCollectionInterface {
  model?: AudioControlEnum;
  ip?: string;
}
export interface PlayerSettingsAudioControlInterface {
  enabled?: boolean;
  collection?: PlayerSettingsAudioControlCollectionInterface[];
}

export enum RebootSchedulingEnum {
  weekly = 'weekly',
  monthly = 'monthly',
}

export interface PlayerSettingsRebootHardwareInterface {
  enabled?: boolean;
  schedulingType?: RebootSchedulingEnum;
  weekdays?: WeekdayInterface[];
  monthindexes?: MonthIndexInterface[];
  dayOfMonth?: number;
  time?: Date;
}

export class PlayerSettingsAudioControlCollection implements PlayerSettingsAudioControlCollectionInterface {
  model?: AudioControlEnum = AudioControlNames[0].id;
  ip?: string = '';

  constructor(private _conf?: PlayerSettingsAudioControlCollectionInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsAudioControl implements PlayerSettingsAudioControlInterface {
  enabled: boolean = false;
  collection?: PlayerSettingsAudioControlCollectionInterface[] = [];

  constructor(private _conf?: PlayerSettingsAudioControlInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsRebootHardware implements PlayerSettingsRebootHardwareInterface {
  enabled: boolean = false;
  schedulingType: RebootSchedulingEnum = RebootSchedulingEnum.weekly;
  weekdays: WeekdayInterface[] = [];
  monthindexes: MonthIndexInterface[] = [];
  dayOfMonth: number = 1;
  time: Date = startOfDay(new Date());

  constructor(private _conf?: PlayerSettingsRebootHardwareInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

// Else..

export enum PlayerSettingsTransitionEnum {
  cut = 'cut',
  fade = 'fade',
}

export interface PlayerSettingsTransitionInterface {
  duration?: number;
  effect?: PlayerSettingsTransitionEnum;
  garbageTransitionDelay?: number;
}

export interface PlayerSettingsCommonInterface {
  proximityEnabled?: boolean;
  autostartEnabled?: boolean;
  position?: CoordinatesInterface;
  maxStorageIndexDB?: number;
}

export interface PlayerSettingsLogsInterface {
  enabled?: boolean;
  sendEnabled?: boolean;
}

export interface PlayerSettingsScreenshotsInterface {
  enabled?: boolean;
}

export enum PlayerSettingsSenderEnum {
  nova = 'nova',
}

export interface PlayerSettingsSenderInterface {
  type?: PlayerSettingsSenderEnum;
  novaUrl?: string;
}

export enum PlayerSettingsUDPEnum {
  transmission = 'transmission',
  receiver = 'receiver',
}

export interface PlayerSettingsUDPInterface {
  type?: PlayerSettingsUDPEnum;
  transmission?: PlayerSettingsUDPTransmissionInterface;
  receiver?: PlayerSettingsUDPReceiverInterface;
}

export const DEFAULT_UDP_TRANSMISSION_PORT = 1346;

export interface PlayerSettingsUDPTransmissionInterface {
  port?: string;
  ip?: string;
  broadcast?: boolean;
}

export interface PlayerSettingsUDPReceiverInterface {
  port?: string;
}

export class PlayerSettings implements PlayerSettingsInterface {
  notWaitingEndCurrentVideo: boolean = false;
  playerRemoteEnabled: boolean = false;
  brightnessDefault: number;
  sender: PlayerSettingsSenderInterface;
  udp: PlayerSettingsUDPInterface;
  transition: PlayerSettingsTransitionInterface;
  common: PlayerSettingsCommonInterface;
  logs: PlayerSettingsLogsInterface;
  screenshots: PlayerSettingsScreenshotsInterface;
  displayControl: PlayerSettingsDisplayControlInterface;
  audioControl: PlayerSettingsAudioControlInterface;
  rebootHardware: PlayerSettingsRebootHardwareInterface;
  performance: PlayerSettingsPerformanceInterface;

  /**
   * Constructor
   * @param _conf
   */
  constructor(private _conf?: PlayerSettingsInterface) {
    Object.assign(this, this._conf);
    this.sender = new PlayerSettingsSender(this.sender);
    this.udp = new PlayerSettingsUDP(this.udp);
    this.transition = new PlayerSettingsTransition(this.transition);
    this.common = new PlayerSettingsCommon(this.common);
    this.logs = new PlayerSettingsLogs(this.logs);
    this.screenshots = new PlayerSettingsScreenshots(this.screenshots);
    this.displayControl = new PlayerSettingsDisplayControl(this.displayControl);
    this.audioControl = new PlayerSettingsAudioControl(this.audioControl);
    this.rebootHardware = new PlayerSettingsRebootHardware(this.rebootHardware);
    this.performance = new PlayerSettingsPerformance(this.performance);

    // console.log('player settings config', this);

    delete this._conf;
  }
}

export class PlayerSettingsSender implements PlayerSettingsSenderInterface {
  type?: PlayerSettingsSenderEnum = null;
  novaUrl?: string = null;
  constructor(private _conf?: PlayerSettingsSenderInterface) {
    Object.assign(this, this._conf);
    if (this.type !== PlayerSettingsSenderEnum.nova) {
      this.novaUrl = null;
    }
    delete this._conf;
  }
}

export class PlayerSettingsUDP implements PlayerSettingsUDPInterface {
  type: PlayerSettingsUDPEnum = null;
  transmission?: PlayerSettingsUDPTransmissionInterface;
  receiver?: PlayerSettingsUDPReceiverInterface;

  constructor(private _conf?: PlayerSettingsUDPInterface) {
    Object.assign(this, this._conf);
    this.transmission = new PlayerSettingsUDPTransmission(
      this.type === PlayerSettingsUDPEnum.transmission ? this.transmission : {},
    );
    this.receiver = new PlayerSettingsUDPReceiver(this.type === PlayerSettingsUDPEnum.receiver ? this.receiver : {});
    delete this._conf;
  }
}

export class PlayerSettingsUDPTransmission implements PlayerSettingsUDPTransmissionInterface {
  port?: string = null;
  ip?: string = null;
  broadcast?: boolean = false;
  constructor(private _conf?: PlayerSettingsUDPTransmissionInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsUDPReceiver implements PlayerSettingsUDPReceiverInterface {
  port: string = null;
  constructor(private _conf?: PlayerSettingsUDPReceiverInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsCommon implements PlayerSettingsCommonInterface {
  position: CoordinatesInterface;
  proximityEnabled: boolean = false;
  autostartEnabled: boolean = true;
  maxStorageIndexDB: number = null;

  constructor(private _conf?: PlayerSettingsCommonInterface) {
    Object.assign(this, this._conf);
    this.position = new Coordinates(this.position);
    delete this._conf;
  }
}

export class PlayerSettingsLogs implements PlayerSettingsLogsInterface {
  enabled: boolean = true;
  sendEnabled: boolean = true;

  constructor(private _conf?: PlayerSettingsLogsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsScreenshots implements PlayerSettingsScreenshotsInterface {
  enabled: boolean = false;

  constructor(private _conf?: PlayerSettingsScreenshotsInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}

export class PlayerSettingsTransition implements PlayerSettingsTransitionInterface {
  effect: PlayerSettingsTransitionEnum = PlayerSettingsTransitionEnum.fade;
  duration: number = 300;
  garbageTransitionDelay: number = 1500;

  /**
   * Constructor
   * @param _conf
   */
  constructor(private _conf?: PlayerSettingsTransitionInterface) {
    Object.assign(this, this._conf);
    delete this._conf;
  }
}
